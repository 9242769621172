import { TablePagination } from "@mui/material";
import { useState } from "react";

export default function Table({ columns, rows }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Calculate the pagination details
  const indexOfLastRow = (page + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentItems = rows?.length > 0 && rows.slice(indexOfFirstRow, indexOfLastRow);
  const count = rows?.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <style>
        {`
    .overflow-y-auto::-webkit-scrollbar {
      width: 9px;
    }
    .overflow-y-auto::-webkit-scrollbar-thumb {
      background-color: rgba(155, 155, 155);
      border-radius: 10px;
    }
    .overflow-y-auto::-webkit-scrollbar-track {
      background-color: rgba(155, 155, 155, 0.1);
    }
  `}
      </style>
      <div className={`flex flex-col mr-2 shadow-md shadow-gray-200 bg-gray-100`}>
        <div className="flex flex-col  overflow-auto z-0 border border-gray-300 h-auto rounded-t-lg rounded-b-lg ">
          <table className="relative w-full">
            <thead className="w-full h-0 text-xs bg-[#EAECF0] font-medium leading-4 tracking-normal font-inter ">
              <tr className="h-0">
                {columns?.map((column, index) => (
                  <th key={index} className="border-r border-gray-200">{column.name}</th>
                ))}
              </tr>
            </thead>
          </table>
          <div className="flex flex-col shadow-md bg-gray-400 shadow-gray-200 ">
            <table className="relative w-full h-full ">
              <tbody className="w-full bg-white ">
                {currentItems && currentItems?.map((row, indexx) => (
                  <tr key={indexx}>
                    {columns?.map((column, index) => (
                      <td
                        key={index}
                        className="py-2 border-t-[1px] border-gray-300"
                      >
                        {column.cell(row, indexx)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <hr className="border-t-[1px] border-gray-300" />
          </div>
        </div>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 30, 50]}
        />
      </div>
    </>
  );
}